import React, { useState, useEffect } from "react";
import { Switch, Route, Link } from "react-router-dom";
import { useFilterStore, useGraphStore } from '../store.js';

import FiltersIcon from "../assets/filters-icon.svg";
import CloseIcon from "../assets/close-icon.svg";

const Navigation = ({ navType = "graphs", graphNav = [], navClickHandler }) => {
    const [navData, setNavData] = useState(graphNav);
    const [useHrefInsteadOfClicks, setUseHrefInsteadOfClicks] = useState(false);

    const navItems = useGraphStore((state) => state.allGraphsNavigation);
    const isFiltersOpen = useFilterStore((state) => state.isFiltersOpen);
    const toggleFilters = useFilterStore((state) => state.toggleFilters);

    useEffect(() => {
        setNavData(graphNav);
    }, [graphNav]);

    const renderGraphsNav = () => (
        navItems.map((graph, index) =>
            !graph.hide && (
                <li className="siteNav-list-item" key={index}>
                    <Link
                        key={graph.url}
                        className={graph.isActive ? "is-active" : "inactive"}
                        to={`detail?graph=${graph.url}`}
                        onClick={!useHrefInsteadOfClicks && navClickHandler ? (event) => navClickHandler(graph.id, navData) : undefined}
                    >
                        {graph.title}
                    </Link>
                </li>
            )
        )
    );

    // const renderClubsNav = () => (
    //     selectAllClubs().map((club, index) => (
    //         <li className="siteNav-list-item" key={index}>
    //             <a
    //                 key={club.id}
    //                 id={`nav-club-id-${club.id}`}
    //                 href="#"
    //                 onClick={navClickHandler}
    //             >
    //                 {club.name}
    //             </a>
    //         </li>
    //     ))
    // );

    return (
        <div className="siteNav-wrapper">
            <nav className="siteNav">
                <ul className="siteNav-list">
                    {renderGraphsNav()}
                    {/* {navType === "clubs" ? renderClubsNav() : renderGraphsNav()} */}
                </ul>
            </nav>
            <button 
                onClick={toggleFilters}
                className="siteNav-toggle-filters">

                {!isFiltersOpen ? <img width="100%" height="100%" src={FiltersIcon}/> : '' }
                {isFiltersOpen ? <img width="100%" height="100%" src={CloseIcon}/> : '' }

            </button>
        </div>
    );
};

export default Navigation;