import React from 'react';
import Header from '.././Elements/Header';
import BodyClassSetter from '../Elements/BodyClassSetter';
import Footer from "../Elements/Footer";
import { getClubById, setNavElementAsActive, prepareNavGraphsObject } from "../Utils";
import MarketView from "../Elements/MarketView";


class MarketShareView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOverview: true,
            selectedClubs: [],
            graphName: "Geographical Spread",
            filterCount: 0,
            filterState: [],
            showKeys: true
        }
        this.state.selectedClubs = getClubById(1)

        this.state.graphNav = prepareNavGraphsObject();
    }
    navClickGraphHandler = (clickedElement) => {
        this.setState({graphName: clickedElement.target.innerText});
    }
    navClickClubHandler = (clickedElement) => {
        this.state.graphName = clickedElement.target.innerText;

    }
    render() {
        const { selectedClubs, graphNav } = this.state;
        return (
            <BodyClassSetter customClass={'MarketShareView'} responsive={true} >
                <Header includeNav navType="graphs" navClickClubHandler={this.navClickClubHandler} navClickGraphHandler={this.navClickGraphHandler} graphNav={graphNav} />
                <MarketView selectedClubs={selectedClubs} />
                <Footer />
            </BodyClassSetter>
        )
    }
}

export default MarketShareView;