import React from "react";
import * as d3 from "d3";
import "./../Spinner.css";
import LinearChartLine from "./LinearChartLine";

const LinearChartSup = ({ data, showKey }) => {
  //Graph heights and widths etc
  const SVGHeight = 400;
  const SVGWidth = 600;
  const MARGIN = 50;
  const graphHeight = SVGHeight - 2 * MARGIN;
  const graphWidth = SVGWidth - 2 * MARGIN;

  const combinedItems = data.reduce((acc, currentValue) => [...acc, ...currentValue.items], [])

  //D3 pos
  const xDomain = [...new Set(combinedItems.map(item => item.label))];
  // const xDomain = combinedItems.map(item => item.label);

  const x = d3
    .scalePoint()
    .domain(xDomain)
    .range([0, graphWidth])

  const yDomain = d3.extent([...new Set(combinedItems.map(item => item.value))]);
  const y = d3
    .scaleLinear()
    .domain(yDomain)
    .range([-graphHeight / 4, -graphHeight / 2])

  var yy = y(0);
  if (yy < -470) {
    yy = -300;
  }

  var textPos = y(0 + graphHeight)
  if (textPos < -470) {
    textPos = -300;
  }

  return (
    <div className="chart-wrapper linear-chart-wrapper">
      <svg className="linear-chart" viewBox={`0 0 ${SVGWidth} ${SVGHeight}`}>
        <g transform="translate(50 350)">
          <line
            x1={-20}
            x2={graphWidth + 20}
            y1={yy}
            y2={yy}
            stroke="#AAA"
            strokeWidth="1"
            strokeDasharray="2"
          />

          <text
            textAnchor="middle"
            y={textPos}
            x={graphWidth + 30}
          >
            0
        </text>

          {/* Axes */}
          {combinedItems.map((item, i) => (
            <g key={i}>

              <line
                className="linear-line"
                key={"item" + i}
                x1={x(item.label)}
                x2={x(item.label)}
                y1={0}
                y2={-graphHeight}
                stroke="#AAA"
                strokeWidth="1"
                strokeDasharray="2"
              />
              <text x={x(item.label)} y={30} textAnchor="middle">
                {item.label}
              </text>    
          </g>
          ))}

          {/* <path d={Area(items)} fill="blanchedalmond" opacity="0.75" strokeWidth="2" /> */}
          {data.map((singleClub,i) => <LinearChartLine key={i} colour={singleClub.colour} items={singleClub.items} x={x} y={y} />)}
        </g>
      </svg >

      {showKey &&
        <div className="legend">
          {data.map((singleClub, i) => 
            <div key={i} className="legend-item">
              <div className="legend-color" style={{backgroundColor: singleClub.colour}}></div>
              {(singleClub.type && singleClub.type) || "Not defined"}
              {i > 0 ? <span className="legend-text"> (in Thousands)</span> : ""}
          </div>)}
        </div>
      }
    </div>
  );
};


export default (LinearChartSup);