import React from 'react'

class BodyClassSetter extends React.Component {
    static defaultProps = {
        customClass: ''
    }
    componentDidMount() {
        document.body.classList.add(this.props.customClass)
    }
    componentWillReceiveProps(nextProps)
    {
        document.body.classList.replace(document.body.className,nextProps.customClass)

    }
    componentWillUnmount() {
        document.body.classList.remove(this.props.customClass)
    }
    render() {
        return this.props.children
    }
}

export default BodyClassSetter;