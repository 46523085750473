import React from "react";
import Logo from "./Logo";
import Navigation from "./Navigation";
import { Link } from 'react-router-dom';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.tagLine = props.tagLine;
        this.includeNav = props.includeNav;
        this.navType = props.navType;
    }

    render() {
        return (
            <div className="siteHeader-wrapper">
                <header className="siteHeader">
                    <Link to="/main">
                        <Logo />
                    </Link>
                </header>
                {/* {this.tagLine ? <h2><img width="400px" src={require(".././assets/Quote_Navigation_2023.svg")} alt="Let faith oust fact; let fancy oust memory..." /></h2>
                    : ''}
                */}
                <Navigation navClickHandler={this.props.navClickHandler} graphNav={this.props.graphNav} />
            </div>
        )
    }
}
export default Header;