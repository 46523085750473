import React from "react";
import { Box, Button, Text } from "grommet";
import {
  getFormattedValue,
  getMarketShareData,
  getYearGraphData
} from "../Utils";
import HalfCircleChart from "./HalfCircleChart";

class NavigationComplexElement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      club: null,
      belongToclubId: null,
      clickedClubId: null
    };
    this.state.club = props.clubData;
    this.circleClickHandler = props.circleClickHandler;
  }
  componentWillReceiveProps(nextProps, nextContext) {
    this.state.clickedClubId = nextProps.clickedClubId;
  }

  render() {
    const { club } = this.state;
    this.state.belongToclubId = club.id;
    var showDetails = this.state.belongToclubId === this.state.clickedClubId;
    var className = showDetails
      ? "fer-complexNavElementData active"
      : "fer-complexNavElementData inactive";
    var marketshareData = getMarketShareData(club.id);
    var grossTonnagePercent =
      typeof marketshareData != "undefined"
        ? marketshareData.grossTonnagePercent
        : "";
    var grossPremium =
      typeof marketshareData != "undefined" ? marketshareData.grossPremium : "";
    var standardAndPoorRating =
      typeof marketshareData != "undefined"
        ? marketshareData.standardAndPoorRating
        : "";
    var grossTonnageValue =
      typeof marketshareData != "undefined"
        ? marketshareData.grossTonnageValue
        : "";
    var charteredTonnage =
      typeof marketshareData != "undefined"
        ? marketshareData.charteredTonnage
        : "";
    var numberOfVessels =
      typeof marketshareData != "undefined"
        ? marketshareData.numberOfVessels
        : "";
    var marketShareGraphData = getYearGraphData(
      club.name,
      "Market Share",
      "2021"
    );

    return (
      <Button
        className={className}
        id={"clubData-" + club.id}
        onClick={() => {
          this.circleClickHandler(club.id);
        }}
        onMouseLeave={Event => {
          this.props.mouseLeaveHandler(Event.type, club, Event.target);
        }}
      >
        <Box direction={"row"} align="center" justify="end">
          <img
            className="fer-complexNavElement-logo-wrapper"
            align="center"
            width="70"
            src={require(".././assets/clubLogos/" + club.logoFile)}
            alt="North Logo"
          />

          <HalfCircleChart
            id={"marketShare" + club.id}
            graphData={marketShareGraphData}
          />

          <Box margin={{ left: "24px" }}>
            <Text
              color={"fBlack"}
              margin={{ bottom: "3px", top: "3px" }}
              size="12px"
            >
              <Text size="12px" color={"fBlue"} weight={600}>
                {grossTonnagePercent}% &nbsp;
              </Text>
              Gross tonnage
            </Text>
            <Text
              color={"fBlack"}
              margin={{ bottom: "3px", top: "3px" }}
              size="12px"
            >
              <Text size="12px" color={"fBlue"} weight={600}>
                {grossPremium}% &nbsp;
              </Text>
              Gross premium
            </Text>
          </Box>
          <Box margin={{ left: "24px" }}>
            <Text color={"fBlack"} size="12px"></Text>
          </Box>
          <Box margin={{ left: "24px" }}>
            <Text color={"fBlack"} margin={{ bottom: "6px" }} size="12px">
              Standard & pool rating
            </Text>
            <Text color={"fBlack"} size="12px" weight={600}>
              {standardAndPoorRating}
            </Text>
          </Box>
          <Box margin={{ left: "24px" }}>
            <Text color={"fBlack"} margin={{ bottom: "6px" }} size="12px">
              Gross Tonnage Value
            </Text>
            <Text color={"fBlack"} size="12px" weight={600}>
              {getFormattedValue(grossTonnageValue, "decimal")}
            </Text>
          </Box>
          <Box margin={{ left: "24px" }}>
            <Text color={"fBlack"} margin={{ bottom: "6px" }} size="12px">
              Chartered Tonnage
            </Text>
            <Text color={"fBlack"} size="12px" weight={600}>
              {getFormattedValue(charteredTonnage, "decimal")}
            </Text>
          </Box>
          <Box margin={{ left: "24px" }}>
            <Text color={"fBlack"} margin={{ bottom: "6px" }} size="12px">
              Number of Vessels
            </Text>
            <Text color={"fBlack"} size="12px" weight={600}>
              {getFormattedValue(numberOfVessels, "decimal")}
            </Text>
          </Box>
        </Box>
      </Button>
    );
  }
}
export default NavigationComplexElement;
