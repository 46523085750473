import React, { Fragment } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import Banner from '.././Elements/Banner';
import DoughnutChart from './DoughnutChart';
import StackedBars from "./StackedBars";
import CircleChart from './CircleChart';
import LinearChart from "./LinearChart";
import HalfCircleChart from "./HalfCircleChart";
import StackedArea from "./StackedArea.js";
import { getClubById, getGraphById, getYearGraphData, selectAllClubs, getMarketShareData, getClubByUrl } from "../Utils";
import MarketShareNavigation from "./MarketShareNavigation";
import LinearChartSup from "./LinearChartSup";
import LinearChartToggle from "./LinearChartToggle";
import { withRouter, Link } from 'react-router-dom';

import { useClubStore } from '../store.js';
class MarketView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedClubs: [],
            clubs: useClubStore.getState().allClubs,
            currentGraph: '',
            currentPeriod: "2024", // TODO: This breaks if doesn't match with dataModel.json
			previousPeriod: "2023", // TODO: This breaks if doesn't match with dataModel.json
            clickedClubId: 1,
            currentIndex: 0,
            marketData: {}
        };
        this.state.selectedClubs = getClubById(1);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedClubs.length > 0) {
            this.setState({ selectedClubs: nextProps.selectedClubs });
        }
        this.setState({ currentGraph: nextProps.currentGraph });
    }
    
    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
		const selectedClubParam = urlParams.get('selected_club');

        if(selectedClubParam){
            const selectedClub = getClubByUrl(selectedClubParam);
            if(selectedClub && selectedClub[0]){
                this.state.currentIndex = selectedClub[0].id - 1;
            }
        }

        this.updateMarketData(); // Fetch initial market data after component mounts
    }

    handleClubOverviewClick(club) {
        useClubStore.setState({ selectedClubs: club });
    }

    updateMarketData = () => {
        const clubId = this.state.clubs[this.state.currentIndex].id;
        const marketData = getMarketShareData(clubId);
        this.setState({ marketData });
    };

    updateGraphTitles = () => {
        const titles = this.state.selectedClubs[0].graphs.map(graph => {
            const graphId = graph['data'].graphId;
            const graphTypeData = getGraphById(graphId);
            return graphTypeData[0]?.title;
        }).filter(title => title !== undefined);

        this.setState({ graphTitles: titles });
    };

    getGraphType(graphType, graphData, i) {
        let element = '';
        switch (graphType) {
            case "stacked-bars":
				element = <StackedBars showValues={true} showKey={false} graphData={graphData} isPercentage={false} />
				break;
			case "doughnut-chart":
				element = <DoughnutChart showValues={true} showKey={this.props.showKeys} graphData={graphData} isPercentage={true} />
				break;
            case "halfcircle":
                element = <HalfCircleChart key={`fer-halfCircle-${i}`} showKey={this.props.showKeys} graphData={graphData} keyMouseEnterHandler={this.graphMouseEnterHandler} keyMouseLeaveHandler={this.graphMouseLeaveHandler} />;
                break;
            case "linear":
                element = <LinearChart key={`fer-linearChart-${i}`} data={graphData} />;
                break;
            // case "linear-xaxis":
            //     element = <LinearChartSup key={`fer-linearChartSup-${i}`} data={graphData} />;
            //     break;
            case "linear-toggle":
                element = <LinearChartToggle key={`fer-linearChartSup-${i}`} data={graphData} />;
                break;
            case "stacked-area":
                element = <StackedArea graphData={graphData} showTitle={0} showSubtitle={0} showKey={false} showValues={true}/>
                break;	    
            default:
                element = <CircleChart key={`fer-concentricCircle-${i}`} showKey={this.props.showKeys} currentGraphData={graphData} keyMouseEnterHandler={this.graphMouseEnterHandler} keyMouseLeaveHandler={this.graphMouseLeaveHandler} />;
        }

        return element;
    }

    getGraph(graph, i) {
        const graphId = graph['data'].graphId;
        const graphTypeData = getGraphById(graphId);

        if (graphTypeData[0] && graphTypeData[0].hide) {
            return null;
        }

        const graphType = graphTypeData[0].graphType;
        const graphUrl = graphTypeData[0].url;
        const { currentPeriod, previousPeriod } = this.state;
        const graphTitle = graphTypeData[0].title;

        const clubname = this.state.clubs[this.state.currentIndex].name
        
        const curGraphData = getYearGraphData(clubname, graphTitle, currentPeriod);
        const prevGraphData = getYearGraphData(clubname, graphTitle, previousPeriod);

        const graphData = {
            currentPeriod: curGraphData,
            previousPeriod: prevGraphData,
        };

        const element = this.getGraphType(graphType, graphData, i);

        return (
            <a
                id={"graph-" + i}
                key={i}
                className={"fer-graphWrapper"}
                href={`/detail?graph=` + graphUrl}
            >
                <div className={"fer-graphWrapper-header"}>
                    <h3 className="fer-graphWrapper-header-title">{graphTitle}</h3>
                    <p className="font-secondary">{previousPeriod} - {currentPeriod}</p>
                </div>
                <Fragment>
                    {this.props.showKeys ? currentPeriod : ""}
                    {element}
                </Fragment>
            </a>
        );
    }

    getGrid() {
        return (
            <>

                {/* Carousel Box */}
                    
                {/* Mobile */}

                <nav className="clubCarousel-panel-wrapper mobile">
                    <div className="clubCarousel-panel">
                        <div className="clubCarousel-panel-header">
                            <p className="clubCarousel-panel-header-title h3">Club overview</p>
                            <p className="font-secondary">{this.state.previousPeriod} - {this.state.currentPeriod}</p>
                        </div>
                        <Swiper
                            autoHeight
                            autoplay
                            slidesPerView={3}
                        >
                        {this.state.clubs.map((club, index) => (
                            <SwiperSlide key={index}>
                                <Link to={`/detail?graph=club-overview&selected_club=${club.url}`}>
                                    <img
                                        className="clubCarousel-panel-swiper-logo"
                                        src={require(`.././assets/clubLogos/${club.logoFile}`)}
                                        alt="Club Logo"
                                    />
                                <h2 className="clubCarousel-panel-swiper-title p">{club.name}</h2>
                               </Link>
                            </SwiperSlide>
                        ))}
                        </Swiper>
                    </div>
                </nav>

                {/* Desktop */}
                <nav className="clubCarousel-panel-wrapper desktop">
                    <div className="clubCarousel-panel">
                        <small>Current Market Share</small>
                        <h2 className="clubCarousel-panel-title">{this.state.clubs[this.state.currentIndex].name}</h2>
                        {/* Logo and Navigation Box */}
                        <div className="clubCarousel-panel-header">
                            {/* Logo */}
                            <img
                                className="clubCarousel-panel-header-logo"
                                src={require(`.././assets/clubLogos/${this.state.clubs[this.state.currentIndex].logoFile}`)}
                                alt="Club Logo"
                            />
                            {/* Navigation Buttons */}
                            <nav className="clubCarousel-panel-header-nav">
                                <button
                                    className="previous"
                                    type="button"
                                    disabled={this.state.currentIndex === 0}
                                    onClick={this.previousName}
                                >
                                    <svg width="20" height="21" viewBox="0 0 20 21" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.839 10.9808H3.91833V9.815H13.839L9.16979 5.14604L10 4.31833L16.0817 10.4L10 16.4817L9.16979 15.654L13.839 10.9808Z" fill="#3F4B54"/>
                                    </svg>
                                </button>
                                <button
                                    className="next"
                                    type="button"
                                    disabled={this.state.currentIndex === this.state.clubs.length - 1}
                                    onClick={this.nextName}
                                >
                                    <svg width="20" height="21" viewBox="0 0 20 21" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.839 10.9808H3.91833V9.815H13.839L9.16979 5.14604L10 4.31833L16.0817 10.4L10 16.4817L9.16979 15.654L13.839 10.9808Z" fill="#3F4B54"/>
                                    </svg>
                                </button>
                            </nav>
                        </div>

                        {/* Data Texts with Adjusted Layout for Text and Stats */}

                        <div className="clubCarousel-panel-content">
                            <dl>
                                {[
                                    { label: 'Owned Tonnage', key: 'grossTonnagePercent' },
                                    { label: 'Premium', key: 'grossPremium' },
                                    { label: 'Number of vessels', key: 'numberOfVessels' },
                                    { label: 'Standard & Poor\'s rating (Interactive rating)', key: 'standardAndPoorRating' },
                                    { label: 'Gross tonnage (Mutual owned) Feb24 (in mGT)', key: 'grossTonnageValue' },
                                    { label: 'Chartered tonnage (in mGT)', key: 'charteredTonnage' }
                                ].map((item, index) => (
                                    <React.Fragment key={index}>
                                        <dt>{item.label}</dt>
                                        <dd>
                                            {this.state.marketData[item.key] ?
                                                `${this.state.marketData[item.key]}${item.key === 'grossTonnagePercent' || item.key === 'grossPremium' ? '%' : ''}`
                                                : 'N/A'}
                                        </dd>
                                    </React.Fragment>
                                ))}
                            </dl>

                            {/* Club Overview Button */}

                            <div className="clubCarousel-panel-footer">
                                {/* TODO: Button component */}
                                <Link 
                                    className="button" 
                                    to={`/detail?graph=club-overview&selected_club=${this.state.clubs[this.state.currentIndex].url}`}
                                >
                                    Club Overview
                                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.839 10.9808H3.91833V9.815H13.839L9.16979 5.14604L10 4.31833L16.0817 10.4L10 16.4817L9.16979 15.654L13.839 10.9808Z" fill="#E8EAED"/>
                                    </svg>
                                </Link>
                            </div>
                        </div>
                    </div>
                </nav>

                {/* Right Column: Graphs */}

                <div className="mainView-wrapper">
                    <div className="mainView market-view">
                        <div className="fer-gridWrapper">
                            {this.state.selectedClubs[0].graphs.map((graph, i) => (
                                <Fragment key={`fer-fragment-${i}`}>
                                    {this.getGraph(graph, i)}
                                </Fragment>
                            ))}
                        </div>
                    </div>
                </div>
            </>
        );
    }

    previousName = () => {
        this.setState(
            prevState => ({
                currentIndex: (prevState.currentIndex - 1 + prevState.clubs.length) % prevState.clubs.length
            }),
            this.updateMarketData // Call after setting the new index
        );
    };

    nextName = () => {
        this.setState(
            prevState => ({
                currentIndex: (prevState.currentIndex + 1) % prevState.clubs.length
            }),
            this.updateMarketData // Call after setting the new index
        );
    };

    render() {
        return (
            <Fragment>
                <Banner title="P&I Market Review 2024" />
                <main>
                    {this.getGrid()}
                </main>
            </Fragment>
        );
    }
}

export default withRouter(MarketView);
