import React from 'react';
import Home from './Pages/Home';
import DetailView from "./Pages/DetailView";
// import TempNav from './Pages/TempNav';
import MarketShareView from './Pages/MarketShareView';
import { Switch, Route } from "react-router-dom";
import './App.scss';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

function App() {
	return (
        <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/detail" component={DetailView} />
            {/* <Route exact path="/tempNav" component={TempNav} /> */}
            <Route exact path="/main" component={MarketShareView} />
        </Switch>
	);
}
export default App;