import React from "react";
class Footer extends React.Component {
    constructor(props) {
        super(props);

        this.tagLine = props.tagLine;
        this.includeNav = props.includeNav;

    }
    render() {
        const companyName = "©P.L Ferrari & Co.";
        return (
            <div className="siteFooter-wrapper">
                <footer className="siteFooter">
                    <div className="download">
                        <a href={require(".././assets/download/Lockton_Marine_P&I_Market_Review_2024.pdf")} download>
                        <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.38867 11.3743C7.78247 11.7628 8.41563 11.7596 8.80286 11.3671L15.1132 4.97105C15.5004 4.57855 15.4951 3.94541 15.1013 3.55689C14.7075 3.16836 14.0744 3.17158 13.6871 3.56407L8.07792 9.24948L2.37356 3.62157C1.97976 3.23305 1.3466 3.23626 0.959367 3.62876C0.572132 4.02125 0.577458 4.6544 0.971262 5.04292L7.38867 11.3743ZM7.00014 0.00508229L7.08983 10.6687L9.08981 10.6586L9.00011 -0.00508229L7.00014 0.00508229Z" fill="white"/>
                        </svg>
                        Download P&amp;I Report
                        </a>
                    </div>

                    {/* <div>
                        {companyName} <br></br>
                        <a href="https://www.pangaeacreative.co.uk/" target="_blank" rel="noopener noreferrer">
                            Designed by Pangaea
                        </a>
                    </div> */}
                </footer>
            </div>
        )
    }
}
export default Footer;