import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Chart, BarController, BarElement, CategoryScale, LinearScale } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(ChartDataLabels, BarController, BarElement, CategoryScale, LinearScale);

function hexToRGBA(hex, alpha) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

function StackedBars({ graphData, showValues, showKey, isPercentage }) {
    const chartRef = useRef(null);
    const chartInstance = useRef();
    const [wrapperWidth, setWrapperWidth] = useState('10%');
    const [isMobile, setIsMobile] = useState(false);
    const [overviewButtonChecked, setOverviewButtonChecked] = useState(false); // State for button checked

    // Memoize sorted data and colors
    const { currValue, prevValue, currColour, prevColour, labels, sortedCurrentItems } = useMemo(() => {
        const sortedCurrentItems = [...graphData.currentPeriod.items].sort((a, b) => a.title.localeCompare(b.title));
        const sortedPreviousItems = [...graphData.previousPeriod.items].sort((a, b) => a.title.localeCompare(b.title));

        let currValue = sortedCurrentItems.map(item => item.value);
        let prevValue = sortedPreviousItems.map(item => item.value);
        const currColour = sortedCurrentItems.map(item => item.color);
        const prevColour = sortedPreviousItems.map(item => hexToRGBA(item.color, 0.5));
        
        // Calculate percentages if required
        if (isPercentage) {
            const currTotal = currValue.reduce((a, b) => a + b, 0);
            const prevTotal = prevValue.reduce((a, b) => a + b, 0);
            currValue = currValue.map(value => ((value / currTotal) * 100).toFixed(2));
            prevValue = prevValue.map(value => ((value / prevTotal) * 100).toFixed(2));
        }
        
        return {
            currValue,
            prevValue,
            currColour,
            prevColour,
            labels: ["2023", "2024"],
            sortedCurrentItems
        };
    }, [graphData, isPercentage]);

    useEffect(() => {
        const overviewButton = document.querySelector('.filterPanel-form-input-wrapper');

        if (overviewButton && overviewButton.classList.contains('is-active')) {
            setOverviewButtonChecked(true);
        }

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // 768px is a common breakpoint for mobile devices
        };

        window.addEventListener('resize', handleResize);

        // Set initial state on mount
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {

        const isFerGridWrapperPresent = !!document.querySelector('.fer-graphWrapper');
        const isMarketViewPresent = !!document.querySelector('.market-view');

        if (!!document.querySelector('.fer-graphBanner-button')) {
            const filterButtons = document.querySelectorAll('[id^="fer-filterBtn-"]');
            const checkedFilterCount = Array.from(filterButtons).filter(button => button.checked).length;
        
            const widthMap = {
                1: '15%',
                2: '25%',
                3: '35%',
            };
        
            let width = widthMap[checkedFilterCount] || (isFerGridWrapperPresent ? '40%' : '10%');
            setWrapperWidth(width);
        } else {
            setWrapperWidth(isFerGridWrapperPresent ? '40%' : '10%');
        }

        const ctx = chartRef.current.getContext('2d');
        
        if (chartInstance.current) {
            chartInstance.current.destroy();
        }

        const datasets = prevValue.map((value, index) => ({
            data: [prevValue[index], currValue[index]],
            backgroundColor: [prevColour[index], currColour[index]],
            categoryPercentage: 1,
            barPercentage: 1,
        }));

        const MIN_SEGMENT_SIZE = 20; // Minimum visible height in pixels for each segment

        const outsideLabelPlugin = {
            id: 'outsideLabelPlugin',
            afterDatasetsDraw(chart) {
                const { ctx, data } = chart;
                
                data.datasets.forEach((dataset, datasetIndex) => {
                    const meta = chart.getDatasetMeta(datasetIndex);
        
                    meta.data.forEach((bar, index) => {
                        const value = dataset.data[index];

                        if (value === 0 || value == "0" || value == "") return;
                        const displayValue = isPercentage ? `${value}%` : value;
        
                        const isSmallBar = bar.base - bar.y < MIN_SEGMENT_SIZE;
        
                        if (isSmallBar) {
                            ctx.save();
                            ctx.fillStyle = dataset.backgroundColor[index];
                            ctx.fillRect(bar.x - bar.width / 2, bar.base - MIN_SEGMENT_SIZE, bar.width, MIN_SEGMENT_SIZE);
                            ctx.restore();
                        }
        
                        // Draw label at the top of each bar segment
                        ctx.save();

                        if(isMarketViewPresent){
                            ctx.font ='bold sans-serif';
                        } else {
                            ctx.font = isFerGridWrapperPresent ? 'bold 8px sans-serif' : 'bold 12px sans-serif';
                        }
                        
                        ctx.textAlign = index === 0 ? 'right' : 'left';
        
                        // Align the label vertically to the top of the bar segment
                        const labelY = isSmallBar ? bar.base - MIN_SEGMENT_SIZE - 2 : bar.y - 2;
                        const labelX = index === 0 ? bar.x - bar.width / 2 - 10 : bar.x + bar.width / 2 + 10;
        
                        ctx.fillText(displayValue, labelX, labelY + 5);
                        ctx.restore();
                    });
                });
            }
        };

        chartInstance.current = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: (overviewButtonChecked || isMarketViewPresent) ? ['', ''] : labels,
                datasets: datasets
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                    padding: isMarketViewPresent
                    ? { left: 60, right: 60, top: 10, bottom: 10 }
                    : (isFerGridWrapperPresent 
                        ? { left: 40, right: 40, top: 10, bottom: 10 }
                        : { left: 60, right: 60, top: 5, bottom: 5 })
                },
                plugins: {
                    legend: { display: false },
                    tooltip: { enabled: false },
                    datalabels: {
                        display: false
                    }
                },
                scales: {
                    x: {
                        display: !isMobile && showValues,
                        stacked: true,
                        position: 'top',
                        ticks: {
                            font: { size: 14, weight: 'bold' },
                            color: '#555555'
                        },
                        offset: true,
                    },
                    y: {
                        display: false,
                        stacked: true,
                        beginAtZero: true,
                    }
                }
            },
            plugins: !isMobile && showValues ? [outsideLabelPlugin] : []
        });

        return () => {
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }
        };

    }, [currValue, prevValue, currColour, prevColour, labels, showValues, isPercentage, isMobile, overviewButtonChecked]);

    return (
        <div className="chart-wrapper">   
            <div className="stacked-bar-chart-wrapper">
                <canvas className="stacked-bar-chart" ref={chartRef}></canvas>
            </div>
         
            {showKey && (
                <>
                    {/* Legend for sorted current items */}
                    <div className="legend">
                        {sortedCurrentItems.map((item, index) => (
                            <div key={index} className="legend-item">
                                <div
                                    className="legend-color"
                                    style={{
                                        backgroundColor: currColour[index],
                                    }}
                                ></div>
                                <span className="legend-text">{item.title}</span>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
}

export default StackedBars;
