import React from 'react';
import Splash from '.././Elements/Splash';

class Home extends React.Component {
	componentWillMount() {
		setTimeout(() => {
			this.props.history.push('/main')
		}, 3500);
	}

	render() {
		return (
			<Splash />
		)
	}
}

export default Home; // withRouter(Home);