import React from "react";
import * as d3 from "d3";
import "./../Spinner.css";
import LinearChartLine from "./LinearChartLine";

const LinearChart = ({ data, showKey, showOnlyFirstKey }) => {

  //Graph heights and widths etc
  const SVGHeight = 400;
  const SVGWidth = 600;
  const MARGIN = 50;
  const graphHeight = SVGHeight - 2 * MARGIN;
  const graphWidth = SVGWidth - 2 * MARGIN;

  const combinedItems = data.reduce((acc, currentValue) => [...acc, ...currentValue.items], [])

  //D3 pos
  const xDomain = [...new Set(combinedItems.map(item => item.label))];
  // const xDomain = combinedItems.map(item => item.label);
  const x = d3
    .scalePoint()
    .domain(xDomain)
    .range([0, graphWidth]);

  const yDomain = d3.extent([...new Set(combinedItems.map(item => item.value))]);
  const y = d3
    .scaleLinear()
    .domain(yDomain)
    .range([0, -graphHeight]);

  return (
    <div className="chart-wrapper linear-chart-wrapper">
      <svg className="linear-chart" viewBox={`0 0 ${SVGWidth} ${SVGHeight}`}>
        <g transform="translate(50 350)">

          {/* Axes */}
          {combinedItems.map((item, i) => (
            <g key={`fer-g-${i}`}>
              <line
                className="linear-line"
                key={"item" + i}
                x1={x(item.label)}
                x2={x(item.label)}
                y1={-graphHeight}
                y2={0}
                stroke="#AAA"
                strokeWidth="1"
                strokeDasharray="2"
              />
              <text x={x(item.label)} y={30} textAnchor="middle">
                {item.label}
              </text>
          </g>
          ))}

          {/* <path d={Area(items)} fill="blanchedalmond" opacity="0.75" strokeWidth="2" /> */}
          {data.map((singleClub,i) => <LinearChartLine key={`fer-linearChart-${i}`} colour={singleClub.colour} items={singleClub.items} x={x} y={y} />)}
        </g>
      </svg>

      {showKey && !showOnlyFirstKey &&
        <div className="legend">
          {data.map(singleClub => <div className="legend-item">
            <div className="legend-color" style={{backgroundColor: singleClub.colour}}></div>
            <span class="legend-text">{(singleClub.type && singleClub.type) || "Not defined"}</span>
          </div>)}
        </div>
      }
      {showKey && showOnlyFirstKey &&
      <div className="legend">
        <div className="legend-item">
          <div className="legend-color" style={{backgroundColor: data[0].colour}}></div>
          <span className="legend-text">{(data[0].type && data[0].type) || "Not defined"}</span>
        </div>
      </div>
      }
    </div>
  );
};


export default (LinearChart);