import React, { useEffect, useState } from "react";
import FilterPanelForm from "./FilterPanelForm";
import { useFilterStore } from "../store.js";

const FilterPanelFormWrapper = ({
	clubs,
	filterGetter,
	currentGraph,
	graphKeyMouseEnterHandler,
	graphKeyMouseLeaveHandler,
	nextGraph,
	prevGraph,
	nextPrevClickHandler,
}) => {

	const isFiltersOpen = useFilterStore((state) => state.isFiltersOpen);

	const [nextGraphState, setNextGraphState] = useState(nextGraph);
	const [prevGraphState, setPrevGraphState] = useState(prevGraph);

	useEffect(() => {
		setNextGraphState(nextGraph);
		setPrevGraphState(prevGraph);
	}, [nextGraph, prevGraph]);

	return (
		<nav className={`filterPanel-wrapper ${isFiltersOpen ? "is-active" : ""}`}>
			<div className="filterPanel-inner-wrapper">
			<header className="filterPanel-wrapper-header">
				<p>Select up to 3 clubs</p>
			</header>
			<FilterPanelForm
				clubs={clubs}
				filterGetter={filterGetter}
				currentGraph={currentGraph}
				graphKeyMouseEnterHandler={graphKeyMouseEnterHandler}
				graphKeyMouseLeaveHandler={graphKeyMouseLeaveHandler}
				nextGraph={nextGraphState}
				prevGraph={prevGraphState}
				nextPrevClickHandler={nextPrevClickHandler}
			/>

			</div>

			<div className="filterPanel-footer">
				<button
					type="button"
					className="filterPanel-footer-button previous"
					onClick={() => nextPrevClickHandler("previous")}
				>
				{prevGraphState && 
					<>
						<svg
							width="20"
							height="21"
							viewBox="0 0 20 21"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M13.839 10.9808H3.91833V9.815H13.839L9.16979 5.14604L10 4.31833L16.0817 10.4L10 16.4817L9.16979 15.654L13.839 10.9808Z"
								fill="#E8EAED"
							/>
						</svg>
						<div className="filterPanel-footer-button-text">{prevGraphState}</div>
					</>
				}
				</button> 
				<button
					type="button"
					className="filterPanel-footer-button next"
					onClick={() => nextPrevClickHandler("next")}
				>
					{nextGraphState && 
						<>
							<div className="filterPanel-footer-button-text">{nextGraphState}</div>
							<svg
								width="20"
								height="21"
								viewBox="0 0 20 21"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M13.839 10.9808H3.91833V9.815H13.839L9.16979 5.14604L10 4.31833L16.0817 10.4L10 16.4817L9.16979 15.654L13.839 10.9808Z"
									fill="#E8EAED"
								/>
							</svg>
						</>
					}
				</button>
			</div>
		</nav>
	);
};

export default FilterPanelFormWrapper;
