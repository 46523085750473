import React, { useEffect, useRef, useState } from 'react';
import { Chart, DoughnutController, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(ChartDataLabels);
Chart.register(DoughnutController, ArcElement, Tooltip, Legend);

function DoughnutChart({ graphData, showValues, showKey, isPercentage }) {
    const chartRef = useRef();
    const chartInstance = useRef();
    const [isOverviewFilterChecked, setIsOverviewFilterChecked] = useState(false);
    const [isOverviewFilterVisible, setIsOverviewFilterVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(false); // State to track if it's mobile

    const labels = ["2023", "2024"];
    const sortedCurrentItems = graphData.currentPeriod.items.sort((a, b) => a.title.localeCompare(b.title));
    const sortedPreviousItems = graphData.previousPeriod.items.sort((a, b) => a.title.localeCompare(b.title));

    let currValue = sortedCurrentItems.map(item => item.value);
    let prevValue = sortedPreviousItems.map(item => item.value);
    const currColour = sortedCurrentItems.map(item => item.color);
    const prevColour = sortedPreviousItems.map(item => item.color);

    if (isPercentage) {
        currValue = currValue.map(value => ((value / currValue.reduce((a, b) => a + b, 0)) * 100).toFixed(2));
        prevValue = prevValue.map(value => ((value / prevValue.reduce((a, b) => a + b, 0)) * 100).toFixed(2));
    }

    const percentageChange = currValue.map((curr, index) => {
        const prev = prevValue[index];
        const currentValue = parseFloat(curr);
        const previousValue = parseFloat(prev);

        if (isNaN(previousValue) || previousValue === undefined || previousValue === null) {
            return `+${Math.floor(currentValue)}`;
        }

        const change = Math.floor(currentValue - previousValue);
        const sign = change >= 0 ? "+" : "";
        return `${sign}${change}%`;
    });

    // Update the isMobile state on resize
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // 768px is a common breakpoint for mobile devices
        };

        window.addEventListener('resize', handleResize);

        // Set initial state on mount
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const overviewFilterButton = document.querySelector('.filterPanel-form-input-wrapper');
        const isDetailView = !!document.querySelector('.is-detail-view');
        const isMarketView = !!document.querySelector('.market-view');


        if (overviewFilterButton && overviewFilterButton.classList.contains('is-active')) {
            setIsOverviewFilterChecked(true);
        }
        setIsOverviewFilterVisible(isDetailView);

        const ctx = chartRef.current.getContext('2d');

        if (chartInstance.current) {
            chartInstance.current.destroy();
        }

        const offsetValue = isMarketView ? 12 : (isOverviewFilterVisible ? (isOverviewFilterChecked ? 12 : 30) : 30);

        chartInstance.current = new Chart(ctx, {
            type: 'doughnut',
            data: {
                labels: sortedCurrentItems.map(item => item.title),
                datasets: [{
                    data: currValue,
                    backgroundColor: currColour,
                    borderWidth: 0
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                cutout: '75%',
                plugins: {
                    legend: { display: false },
                    tooltip: { enabled: false },
                    datalabels: {
                        display: !isMobile && showValues, // Only show values if not mobile
                        align: 'start',
                        anchor: 'left',
                        clamp: true,
                        offset: offsetValue,
                        font: {
                            weight: 'bold',
                            color: '#555555',
                        },
                        formatter: (value, context) => {
                            const index = context.dataIndex;
                            const change = percentageChange[index];
                        
                            const currentValue = currValue[index];
                            const previousValue = prevValue[index];
                        
                            // If both current and previous values are "0.00", return an empty string
                            if (currentValue === "0.00" && previousValue === "0.00") {
                                return '';
                            }
                        
                            const threshold = 0.5;
                        
                            // If the current value is below the threshold, return an empty string
                            if (parseFloat(value) < threshold) {
                                return '';
                            }
                        
                            // If both current and previous values are less than 5, check the next index
                            if (currentValue < 10 && previousValue < 10) {
                                let newIndex = (index > 0) ? index + 1 : currValue.length - 1;
                        
                                // If the next value pair is also less than 5, and above the threshold, return an empty string
                                if (
                                    currValue[newIndex] < 10 &&
                                    prevValue[newIndex] < 10 &&
                                    parseFloat(currValue[newIndex]) > threshold &&
                                    parseFloat(prevValue[newIndex]) > threshold
                                ) {
                                    return '';
                                }
                            }
                        
                            return change;
                        },
                        color: (context) => {
                            const index = context.dataIndex;
                            return currColour[index];
                        },
                    },
                }
            }
        });

        return () => {
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }
        };
    }, [
        graphData, showValues, showKey, isPercentage,
        sortedCurrentItems, currValue, currColour,
        percentageChange, isOverviewFilterChecked, isOverviewFilterVisible, isMobile
    ]);

    return (
        <div className="chart-wrapper doughnut-chart-wrapper">
            {showKey && (
                <>
                    <div className="period-label">{labels[1]}</div>
                    <div className="values-row previous">
                        {currValue.map((value, index) => (
                            <div key={index} style={{ color: currColour[index] }}>
                                {value + "%"}
                            </div>
                        ))}
                    </div>
                </>
            )}

            <div>
                <canvas className="doughnut-chart" ref={chartRef}></canvas>
            </div>

            {showKey && (
                <>
                    <div className="values-row current">
                        {prevValue.map((value, index) => (
                            <div key={index} style={{ color: currColour[index] }}>
                                {value + "%"}
                            </div>
                        ))}
                    </div>

                    <div className="period-label">{labels[0]}</div>

                    <div className="legend">
                        {sortedCurrentItems.map((item, index) => (
                            <div key={index} className="legend-item">
                                <div
                                    className="legend-color"
                                    style={{
                                        backgroundColor: currColour[index],
                                    }}
                                ></div>
                                <span className="legend-text">{item.title}</span>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
}

export default DoughnutChart;
