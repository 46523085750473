import React from "react";
import { Box, Button } from 'grommet';
import { selectAllClubs, getClubUrl } from "../Utils";
import NavigationComplexElement from "./NavigationComplexElement";
import "../Nav.css"
import { withRouter } from 'react-router-dom';

class MarketShareNavigation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            navType: "graphs",
            clickedClubId: null
        }
        this.state.navType = props.navType;
        this.state.clubs = selectAllClubs();
    }
    circleClickHandler = (clubId) => {
        this.props.history.push("detail?clubname=" + getClubUrl(clubId))
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        this.state.clickedClubId = nextProps.clickedClubId;
        this.forceUpdate()
        return nextContext
    }
    getDiameterStyle(diameter){
        let scale = `scale(${diameter})`;
        return {
            transform: scale
        }
    }

    render() {
        const { clubs, clickedClubId } = this.state;
        var showOtherNavElements = this.state.clickedClubId === null ? 1 : 0;
        return (
            clubs.map((club,index) => (

                showOtherNavElements ?
                    <Box key={`ferNavBoxWrap-${index}`} id={club.id} direction={"row"} fill={"horizontal"} justify={"end"} className={"fer-complexNavElement"} >
                        <Button key={`ferNavButton-${index}`} className="fer-complexNavElement-svg-wrapper">
                            <Box key={`ferNavInnerBoxWrap-${index}`} width="20" height="20" onMouseEnter={Event => this.props.mouseEnterHandler(Event.type, club, Event.target)}>
                                <svg key={`ferNavInnerBoxSvg-${index}`} className="fer-complexNavElement-svg" style={this.getDiameterStyle(club.navDiameter)} viewBox="0 0 20 20"><circle fill="#000000" r="10" cx="10" cy="10" /></svg>
                            </Box>
                        </Button>
                        <NavigationComplexElement key={`ferNavComplexEl-${index}`} clubData={club} circleClickHandler={this.circleClickHandler} mouseLeaveHandler={this.props.mouseLeaveHandler} clickedClubId={clickedClubId} />
                    </Box> : club.id === this.state.clickedClubId ?
                        <Box key={`ferNavBoxWrap-${index}`} id={club.id} direction={"row"} fill={"horizontal"} justify={"end"} className={"fer-complexNavElement"} >
                            {this.children}
                            <NavigationComplexElement key={`ferNavComplexEl-${index}`} clubData={club} circleClickHandler={this.circleClickHandler} clickedClubId={clickedClubId} mouseLeaveHandler={this.props.mouseLeaveHandler} />
                        </Box> : ""


            ))

        )
    }
}
export default withRouter(MarketShareNavigation);