import React, {useEffect, useRef, useState} from 'react';
import {
    Chart,
    LineController,
    LineElement,
    PointElement,
    LinearScale,
    CategoryScale,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';
import ChartPluginGradient from 'chartjs-plugin-gradient';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(LineController, LineElement, PointElement, LinearScale, CategoryScale, Tooltip, Legend, Filler, ChartDataLabels, ChartPluginGradient);

function StackedArea({graphData, showTitle, showSubtitle, showValues}) {
    const chartRef = useRef(null);
    const chartInstance = useRef();
    const [wrapperStyle, setWrapperStyle] = useState({ width: '100%', height: '100%' });
    const [isMobile, setIsMobile] = useState(false);

    const getSelectedClubsCount = () => {
        const inputs = document.querySelectorAll("input[id^='fer-filterBtn-']");
        return Array.from(inputs)
            .filter(input => {
                const idNumber = parseInt(input.id.split('-')[2], 10);
                return idNumber > 0 && input.checked; // Only include if the number is greater than 0 and checked
            })
            .length;
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // 768px is a common breakpoint for mobile devices
        };

        window.addEventListener('resize', handleResize);

        // Set initial state on mount
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const ctx = chartRef.current.getContext('2d');

        if (chartInstance.current) {
            chartInstance.current.destroy();
        }

        const updateWrapperStyle = () => {
            const isDesktop = window.innerWidth >= 1024;
            const selectedClubsCount = getSelectedClubsCount();
            const isMainPage = window.location.pathname.includes('/main');

            //Set wrapper to 50% if on desktop, single club selected
            if (isDesktop && selectedClubsCount === 1) {
                setWrapperStyle({ width: '50%', height: '50%' });
            } else {
                setWrapperStyle({ width: '100%', height: '100%' });
            }

            if(isDesktop && isMainPage){
                setWrapperStyle({ width: '70%', height: '70%' });
            }
        };

        // Set initial style and add resize event listener
        updateWrapperStyle();
        window.addEventListener('resize', updateWrapperStyle);

        const dataValues = graphData.currentPeriod[0].items.map(item => item.value);
        const hasPositiveValues = dataValues.some(value => value > 0);

        const data = {
            // labels: graphData.items.map(item => item.label),
            labels: graphData.currentPeriod[0].items.map(item => item.label),
            datasets: [
                {
                    data: dataValues,
                    pointBackgroundColor: dataValues.map(value => value > 0 ? green : red), // Green for positive, red for negative
                    pointBorderColor: dataValues.map(value => value > 0 ? green : red), // Green for positive, red for negative
                    borderWidth: 1,
                    pointRadius: 8,
                    borderColor: dataValues.map(value => value > 0 ? green : red), // Apply green only for positive values
                    pointStyle: 'rect',
                    fill: {
                        target: 'origin',
                        below: 'rgb(252, 210, 194)',
                        above: '#DDECDF'
                    },
                    gradient: {
                        borderColor: {
                            axis: 'y',
                            colors: hasPositiveValues
                                ? { '0': red, 1: green }
                                : { '-1': red, 0: red }
                        },
                        backgroundColor: {
                            axis: 'y',
                            colors: hasPositiveValues
                                ? { '-1': red, 1: green }
                                : { '-1': red, 0: red }
                        },
                    }
                }
            ]
        };
        chartInstance.current = new Chart(ctx, {
            type: 'line',
            data: data,
            options: {
                responsive: true,
                layout: {
                    padding: isMobile
                    ? { left: 0, right: 0, top: 0, bottom: 0 }
                    : { left: 30, right: 30, top: 0, bottom: 0 }
                },
                plugins: {
                    legend: {display: false}, // Hide the legend
                    tooltip: { enabled: false },
                    filler: {},
                    datalabels: {
                        display: !isMobile && showValues,
                        color: (context) => context.dataset.data[context.dataIndex] > 0 ? green : red,
                        align: 'right',
                        anchor: 'end',
                        font: {
                            weight: 'normal',
                            size: 10,
                        },
                        formatter: (value) => value.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                },
                scales: {
                    'right-y-axis': {
                        type: 'linear',
                        position: 'right',
                        ticks: {
                            beginAtZero: true,
                            display: true,
                            major: {enabled: false},
                            callback: function (value) {
                                return value === 0 ? value : '';
                            },
                        },
                        grid: {
                            display: false,
                            drawBorder: false,
                            drawOnChartArea: true,color: function (context)
                            {
                                return context.tick.value === 0 ? '#C4C4C4' : 'rgba(0,0,0,0)';
                            }
                        }
                    },
                    y : {
                        display: false,
                        ticks: {
                            display: false,
                        },
                    },
                    x: {
                        display: !isMobile,
                        ticks: {
                            display: !isMobile,
                        },
                    },
                    yAxis: {
                        ticks: {
                            beginAtZero: true,
                            display: false,
                            major: {enabled: false},
                            callback: function (value) {
                                return value === 0 ? value : '';
                            },
                        },
                        grid: {
                            display: false,
                            drawBorder: true,
                            drawOnChartArea: true
                        }
                    },
                }
            }
        });
    }, [graphData, showTitle, showSubtitle, isMobile]);

    const title = graphData.title;
    const labels = graphData.currentPeriod[0].items.map(item => item.label);
    const dataValues = graphData.currentPeriod[0].items.map(item => item.value);
    const subtitle = labels[1] + " - " + labels[2];

    const green = "#00A34B";
    const red = "#ED1F28";

    return (
        <div className="chart-wrapper stacked-area-chart-wrapper" style={wrapperStyle}>
            {/* {showTitle ? <div className="title">{title}</div> : null}
            {showSubtitle ? <div className="subtitle">{subtitle}</div> : null}  */}
            <canvas className="stacked-area-chart" ref={chartRef}></canvas>
        </div>
    );
}

export default StackedArea;
