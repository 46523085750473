import React, { Fragment } from "react";
import "./../Spinner.css";
import { Box, Button, Heading } from "grommet";
import { LinkUp, LinkDown } from "grommet-icons";
import { getFormattedValue } from "../Utils";

const buildChartData = (graphData) => {
  if (!graphData || !Array.isArray(graphData.items)) {
    return [];
  }
  const sortedItems = (graphData.items && graphData.items.sort((a, b) => b.value - a.value)) || [];
  // Sort by biggest first so the SVG groups get layered properly
  // Sort by biggest first so the SVG groups get layered properly
  const biggestItem = sortedItems[0];
  //Get relative percentage of sorted circles from the the biggest item
  return sortedItems.map((item, i, array) => {
    // Add a single point of radius on for each item in the array
    // (the array is sorted in reverse order, so do some weird maths)
    // This one-pixel radiux shouldn't be noticable on each item, but should show
    // up in contrast between successive items of the same size

    item.radius = (item.value / biggestItem.value * 100) - (i - array.length) * 2;
    // item.radius = (item.value) - (i - array.length) * 2;

    item.isBiggestItem = i === 0; // The first element in the array
    return item;
  })
}
class CircleChart extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      clipCurrentYear: false,
    };
    this.state.clipCurrentYear = props.showPreviousYearGraphData;
    this.previousYearClickHandler = props.previousYearClickHandler;
  }

  handleClipCurrentYear() {
    this.setState(prevState => ({
      clipCurrentYear: !prevState.clipCurrentYear
    }));

  }


  componentDidMount() {
    this.setState({
      isLoaded: true, // This doesn't really make sense as this component doesn't have anything asyncrhonous, but leaving here in case you're going to do something with this later
    });
  }

  // stripSpaces(text) {
  //   return (
  //     text.replace(/\s/g, '')
  //   )
  // }

  render() {
    const { error, isLoaded } = this.state;
    const { showValues, showKey, showPrevYear, currentGraphData, previousGraphData } = this.props;
    const currentItems = buildChartData(currentGraphData); // Moved this into the render as it doesn't need to be stored in state
    const previousItems = buildChartData(previousGraphData);
    const formattingSign = this.props.numberFormat === "percent" ? "%" : "";
    const { isOverview, customKey } = this.props;
    let useCustomKey = typeof customKey === "object" ? true : false;
    let keyItems = useCustomKey ? buildChartData(customKey) : currentItems;

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div className="spinner-icon" role="presentation"></div>;
    } else {
      return (

        <div className={
          this.state.clipCurrentYear ? 'chart-wrapper radial previous-is-active' : 'chart-wrapper radial'
        }>

          {showKey &&
            <Heading color={"fBlack"} level="3" margin="none" size="14px" className="chart-year current">2023</Heading>
          }

          {/* Current year circle */}

          {showValues &&
            <ul className="chart-values current">
              {currentItems.map((item, i) => (
                <Fragment>
                  {item.value >= 1 && item.title != "None" &&
                    <li id={`${item.value}{formattingSign}`} className="chart-key-item" key={i} style={{ color: item.color }} >
                      {getFormattedValue(item.value, this.props.numberFormat)}{formattingSign}
                    </li>
                  }</Fragment>
              ))}
            </ul>
          }
          {/* use biggest item as viewbox? */}
          <svg className="chart-svg radial current" viewBox="0 0 232 232">
            <defs>
              <filter id="brightness">
                <feComponentTransfer>
                  <feFuncR type="linear" slope="2" />
                  <feFuncG type="linear" slope="2" />
                  <feFuncB type="linear" slope="2" />
                </feComponentTransfer>
              </filter>

              <linearGradient id="base" gradientTransform="rotate(90)" x1="100%" y1="50%" x2="0%" y2="50%">
                <stop offset="0%" stopColor="#F5F6F7" />
                <stop offset="100%" stopColor="#ddd" />
              </linearGradient>
            </defs>


            <g className="radial-current">
              {currentItems.map((item, i) => (
                <Fragment key={i}>
                  {item.value >= 1 &&
                    <g aria-labelledby={`${item.title} ${item.value}{formattingSign}`} key={i}>
                      <circle className={`chart-svg-circle color-${item.color}`} cx="116" cy="116" r={item.radius} fill={item.color} />
                    </g>
                  }
                </Fragment>
              ))}
            </g>

          </svg>


          {/* Overlay */}

          {
            showPrevYear &&
            <Box className="overlayButton-wrapper" justify="end" align="start">
              <Box className={
                this.state.clipCurrentYear ? 'overlay is-active' : 'overlay'
              } justify="end" align="start" background={"fSlate3"} />

              {/* Previous year circle*/}

              <svg className="chart-svg radial previous" viewBox="0 0 232 232">
                <defs>
                  <filter id="brightness">
                    <feComponentTransfer>
                      <feFuncR type="linear" slope="2" />
                      <feFuncG type="linear" slope="2" />
                      <feFuncB type="linear" slope="2" />
                    </feComponentTransfer>
                  </filter>

                  <clipPath id="clippedConcentricCirclePrevious">
                    <rect width="260" height="200" y="100" x="-30" />
                  </clipPath>


                  <linearGradient id="base" gradientTransform="rotate(90)" x1="100%" y1="50%" x2="0%" y2="50%">
                    <stop offset="0%" stopColor="#F5F6F7" />
                    <stop offset="100%" stopColor="#ddd" />
                  </linearGradient>
                </defs>


                {
                  this.state.clipCurrentYear &&
                  <g className="radial-previous">
                    {previousItems.map((item, i) => (
                      <Fragment>
                        {item.value >= 1 &&
                          <g aria-labelledby={`${item.title} ${item.value}{formattingSign}`} key={i}>
                            < circle clipPath="url(#clippedConcentricCirclePrevious)" className={`chart-svg-circle ${item.id}`} cx="116" cy="116" r={item.radius} fill={item.color} />
                          </g>
                        }
                      </Fragment>
                    ))}
                  </g>
                }
              </svg>

              {showValues && this.state.clipCurrentYear &&
                <ul className="chart-key previous">
                  {previousItems.map((item, i) => (
                    <Fragment>
                      {item.value >= 1 &&
                        <li id={`${item.id}{formattingSign}`} className="chart-key-item 88888" key={i} style={{ color: item.color }} >
                          {getFormattedValue(item.value, this.props.numberFormat)}{formattingSign}
                        </li>
                      }
                    </Fragment>
                  ))}
                </ul>
              }


              <Button onClick={(event) => { this.handleClipCurrentYear(); this.previousYearClickHandler(!this.state.clipCurrentYear, this.props.index) }} className={this.props.index !== 0 ? "overlayButton disabled" : "overlayButton"} >
                {showKey === true ?
                  <Box background={"fSlate3"} direction="row" align="center" pad={{ left: "24px", right: "24px", top: "10px", bottom: "10px" }}>
                    {this.state.clipCurrentYear ? <LinkDown color={"fBlack"} align="end" size="small" /> : <LinkUp color={"fBlack"} align="end" size="small" />}
                    <Heading className="chart-year" color={"fBlack"} level="3" margin={{ left: "7px", bottom: "0" }} size="14px">2022</Heading>
                  </Box> : isOverview ? " " :
                    <Box background={"fSlate3"} direction="row" align="center" pad={{ left: "24px", right: "24px", top: "10px", bottom: "10px" }}>
                      <Heading color={"fBlack"} level="3" margin={{ left: "7px", bottom: "0" }} size="14px">&nbsp;</Heading>
                    </Box>
                }
              </Button>


            </Box>
          }


          {showKey &&
            <ul className="chart-key">
              {keyItems.map((item, i) => (
                <Fragment>
                  {item.value >= 1 && item.title != "None" &&
                    <li
                      // id={this.stripSpaces(item.title)}
                      className="chart-key-item" key={i}
                    //onMouseEnter={() => this.props.keyMouseEnterHandler(this.stripSpaces(item.title + "-" + i))}
                    //onMouseLeave={() => this.props.keyMouseLeaveHandler(this.stripSpaces(item.title + "-" + i))}
                    >
                      <svg className="chart-key-svg" viewBox="0 0 10 10">
                        <circle cx="5" cy="5" r="3" fill={item.color} />
                      </svg>
                      {item.title}
                    </li>
                  }
                </Fragment>
              ))}
            </ul>
          }
        </div >
      );
    }
  }
}

export default (CircleChart);