import React from 'react';
import * as d3 from "d3";
import {getFormattedValue} from "../Utils"

const LinearChartLine = ({ colour = "black", items, x, y }) => {

  const Line = d3
    .line()
    .x(item => x(item.label))
    .y(item => y(item.value))

  d3.select("text");
  return (
    <g>
      <path className="linear-path" d={Line(items)} stroke={colour} fill="none" strokeWidth="3" />
      {items.map((item, i) =>
        <g key={`fer-linearPath-${i}`}>
          <circle
            key={item.label}
            cx={x(item.label)}
            cy={y(item.value)}
            r={8}
            fill={colour}
          />
          <text
            transform="translate(-15 -15)"
            fontSize=".75em"
            fill={colour}
            y={y(item.value)}
            x={x(item.label)}
          >{getFormattedValue(JSON.stringify(item.value).replace(/\\"/g, ''),"decimal")}</text>

        </g>
      )}
    </g>
  );
};

export default LinearChartLine;