import React from 'react';
class Splash extends React.Component {

  render() {

    return (
      <div className="splash-wrapper">
          <div className="splash">
              <div className="splash-header">
                  <img
                    className="splash-logo"
                    src={require(".././assets/home/2024/Lockton_Logo_2024.svg")}
                    alt="Stockton Marine"
                  />
              </div>


              <h1 className="splash-title">
                  <img
                    src={require(".././assets/home/2024/Title_2024.svg")}
                    alt="P&amp;I Market Review 2024"
                  />
              </h1>

              <div className="splash-footer">
                  {/* <h3 className="splash-quote">
                    "...don't gobblefunk around with words..."
                  </h3> */}
                  <div className="splash-footer-logos">
                    <img
                      src={require(".././assets/home/2024/Lockup_Omni_2024.svg")}
                      alt="Lockup Omni"
                    />
                    <img
                      src={require(".././assets/home/2024/Lockup_PLF_2024.svg")}
                      alt="Lockup PLF"
                    />
                    <img
                      src={require(".././assets/home/2024/Lockup_Edge_2024.svg")}
                      alt="Lockup Edge"
                    />
                  </div>
              </div>
          </div>
      </div>
    )
  }
}

export default (Splash);