import React, { Fragment } from "react";

class Banner extends React.Component {
    render() {
        const { title } = this.props; // Destructure graphTitle from props
        return (
            <Fragment>
                {/* <div className="OverviewView-banner-animation"></div> */}
                <div className="siteBanner">
                    <h1>{title}</h1>
                </div>
            </Fragment>
        );
    }
}

export default Banner;