import React from "react";
import LogoSVG from "../assets/Lockton Marine_RGB_NEG_2023.svg";

class Logo extends React.Component {
    render() {
        return (
            <img 
                className="siteHeader-logo"
                width="150" 
                src={LogoSVG} 
                alt="P&amp;I Market Review 2023"
            />
        );
    }
}

export default Logo;