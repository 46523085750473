import React from "react";
import "./../Spinner.css";
import { Heading } from "grommet";
import arrayMove from "array-move";
import {_findIndex} from "../Utils";
const buildChartData = graphData => {
  if (!graphData) {
	return;
  }

  const sortedItems = graphData.items.sort((a, b) => a.value - b.value);
  // Sort by biggest first so the SVG groups get layered properly
  const biggestItem = sortedItems[1];

  //Get relative percentage of sorted circles from the the biggest item
  return sortedItems.map((item, i) => {
	item.proportionOfBiggest = (item.value / biggestItem.value) * 100;
	item.isBiggestItem = i === 0; // The first element in the array
	return item;
  });
};
class HalfCircleChart extends React.Component {
  constructor(props) {
	super(props);
	this.state = {
	  isLoaded: false,
	  items: []
	};

	this.graphData = props.graphData;
  }

  componentDidMount() {
	this.setState({
	  isLoaded: true
	});
  }

  stripSpaces(text) {
	return text.replace(/\s/g, "");
  }

  render() {
	const { error, isLoaded } = this.state;
	const { showValues, showKey } = this.props;
	let items = buildChartData(this.props.graphData);

	if (items) {
	  items = arrayMove(
		items,
		_findIndex(items,"Gross tonnage"),
		0
	  );
	}

	if (!items) {
	  return '';
	}
	

	if (error) {
	  return <div>Error: {error.message}</div>;
	} else if (!isLoaded) {
	  return <div className="spinner-icon" role="presentation"></div>;
	} else {
	  return (
		<div className="chart-wrapper half-circle-chart-wrapper">
		  {showKey && (
		  	<div className="period-label">2023</div>
		  )}

		  {showValues && (
			<div className="values-row current">
			  {items.map((item, i) => (
				<div
				  key={i}
				  id={`${item.value}%`}
				  style={{ color: item.color }}
				>
				  {item.value}%
				</div>
			  ))}
			</div>
		  )}

		  <svg className="half-circle-chart" viewBox="0 0 100 100">
			<g className="half-radial-path-group">
			  {items.map((item, i) => (
				<g
				  aria-labelledby={`${item.title} ${item.value}%`}
				  key={i}
				>
				  {i === 0 ? (
					<path
					  d={` M${100 + item.proportionOfBiggest / 2}, 50 a1, 1 0 0, 0 -${item.proportionOfBiggest} 0`}
					  fill={item.color}
					/>
				  ) : (
					  <path d={` M${100 - item.proportionOfBiggest / 2}, 50 a1, 1 0 0, 0 ${item.proportionOfBiggest}, 0`} fill={item.color} />
					)}
				</g>
			  ))}
			</g>
		  </svg>

		  {showKey && (
			<div className="legend">
			  {items.map((item, index) => (
				<div key={index} className="legend-item">
					<div
						className="legend-color"
						style={{
							backgroundColor: item.color,
						}}
					></div>
					<span className="legend-text">{item.title}</span>
				</div>
			  ))}
			</div>
		  )}
		</div>
	  );
	}
  }
}

export default HalfCircleChart;
